
import {menuTransitionShow, menuTransitionHide} from '../helpers/menuTransation'
import {config} from '/config.js'


export default {
  name: 'default',
  data() {
    return {
      copyright: config.copyright,
      trigger: true,
      config: config,
    }
  },
  computed: {
    showNFTSLink() {
      return this.$auth.user != null && (this.$auth.user.ethAccount != null || this.$auth.user.tezosAccount != null || this.$auth.user.polkadotAccount != null || this.$auth.user.nearAccount != null)
    },
    defaultChain() {
      return this.$store.state.wallet.defaultChain
    },
    hideMenu() {
      return this.$route.name == "Login"
    }
  },
  // async asyncData ({ $axios }) {
  // },
  watch: {
    $route() {
      if(!this.trigger)
        this.menuTrigger()
    },
  },
  methods:{
    menuTrigger: function(){
      console.log(this.$route)
          if(this.trigger === true){
              menuTransitionShow()
              this.trigger = false
          }
          else{
              menuTransitionHide()
              this.trigger = true
          }
    },
    logout: async function() {
        await this.$auth.logout();
        this.$router.replace('/login')
      },
  }
};
